.container{
    width: 90vh;
    background-color: #f9f9f9;
}

.choice{
    padding-left: 7%;
    padding-right: 7%;
}

.selectedClient{
    margin: 20px;
    padding: 20px;
    padding-left: 5%;
    padding-right: 5%;
}

.buttonDuplicate{
    margin:auto;
}

.center{
    font-size: 23px;
    text-align: center;
    padding: 25px;
  }

.margin3{
    margin-top: 3%;
}

.bold{
    font-weight : bold;
}
