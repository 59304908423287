.goBack {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin: 0;
  padding: 0;
  font-size: 14px;
  margin-bottom: 10px;
}

.goBack > svg {
  margin-right: 5px;
}

.adjustPos {
  position: relative;
  top: 5px;
  margin-right: 8px;
}

.livraisonInfos {
  padding-top: 10px;
  bottom: 9px;
  margin-left: 12px;
  background-color: white;
}

.blockInput {
  margin-top: 20px;
  background-color: white;
  margin-left: 12px;
  padding: 5px;
}

.tooltipBtn {
  cursor: pointer;
  border: 1px solid black;
  padding: 5px;
  width: 24px;
  height: 24px;
  border-radius: 20px;
  z-index: 6;
}

.tooltipBtn:hover {
  border: 1px solid black;
  background-color: black;
}

.tooltipBtn:hover svg {
  color: white;
}

.tooltipBtn .tooltiptext {
  visibility: hidden;
  /* visibility: visible; */
  min-width: 200px;
  max-width: 250px;
  background-color: rgb(8, 8, 8);
  color: rgb(241, 241, 241);
  text-align: center;
  border-radius: 6px;
  padding: 10px;
  /* Position the tooltip */
  position: relative;
  right: 80px;
  top: 15px;
}

.tooltipBtn:hover .tooltiptext {
  visibility: visible;
}

.deleteBtn {
  position: fixed;
  top: 14px;
  right: 200px;
}

.saveBtn {
  position: fixed;
  top: 14px;
  right: 250px;
}

.customUI {
  border: 1px solid black;
  border-radius: 5px;
  padding: 8px;
  height: 142px;
  width: 300px;
}

.customUI h2 {
  margin-top: 5px;
}
.customUI button {
  float: right;
  position: relative;

  font-family: sans-serif;

  margin: 0 0 0 10px;
  background-color: black;
  border: 1px solid black;
  padding: 8px;
  color: white;

  cursor: pointer;
}
