/* ----------------------------------------------------------------------- */
.orderView {
    color: black;
    padding: 5px;
    font-size: 14px;
}

.animeTemplate {
  width: 60%;
  height: 50%;
  overflow-y: auto;
  border-radius: 5px;
  border: 1px solid rgb(87, 87, 87);

  padding: 15px;
  z-index: 10;

  margin-left: auto;
  margin-right: auto;

  position: fixed;
  right: 0px;
  left: 0px;
  top: 62px;
  transition: width 2s, height 2s, background-color 2s, transform 0.8s;
}

.animeHidden {
  composes: animeTemplate;
  background-color: rgb(253, 252, 252);
  transform: translateY(-2000px)
}
  
.animeShow {
  composes: animeTemplate;
  background-color: #eeedf0;
}

.adjustPos {
  position: relative;
  top: 5px;
  margin-right: 5px;
}

.dataClient h4{
  margin: 0px;
}

.adrLivr h4{
  margin: 0px;
}

.cartTableHeader {
  border-bottom: 1px solid rgba(112, 112, 112, 0.2);
  padding: 4px;
  text-align: center;
  background: white;
}

.cartTableRow {
  padding: 4px;
  /* text-align: center; */

  background: #f9f9f9;
  border-bottom: solid 2px white;
  border-top: none;
}

.txtCenter {
  text-align: center;
}

.ref {
  hyphens: auto;
  margin: 5px 0;
  word-wrap: break-word;
  font-weight: 600;
}

.productDesignation {
  margin-bottom: 0;
}

.blockImg {
  /* padding: 10px; */
  width: 40%;
  min-height: 30px;
  max-height: 70px;
  background-color: #eeedf0;
  text-align: center;
}

.blockImg img {
  display: block;
  max-height: 70px;
  max-width: 50%;
  margin: auto;
  text-align: center;
}