.inputContainer {
  /* margin: 0 0 1.5rem; */
  margin: 0 10px;
}

.twoFieldsRow .inputContainer {
  width: 45%;
}
.inputContainer input::placeholder {
  color: #757575;
  font-size: 0.8125rem;
  font-family: "ITC Avant Garde Std text";
  line-height: 1.3125rem;
  overflow: hidden;
  white-space: nowrap;
}

.twoFieldsRow {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}

.select {
  border: none;
  background: white;
  overflow: auto;
  width: 100%;
  outline: none;
}
.inputSpan {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  font-family: "ITC Avant Garde Std text";
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 3.0625rem;
  padding: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: 0.0625rem solid #cecece;
  background-color: #fff;
  border-radius: 0.3125rem;
  position: relative;
  width: 100%;
}

.inputSpan input {
  font-family: "ITC Avant Garde Std text";
  border: 0;
  border-radius: 0.3125rem;
  font-size: 0.8125rem;
  line-height: 1.3125rem;
  width: 100%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background-color: transparent;
  outline: none;
}

.errorText {
  margin-top: 0.1875rem;
  font-family: "ITC Avant Garde Std text";
  text-align: right;
  font-size: 0.8125rem;
  line-height: 1.3125rem;
  min-height: 0.8125rem;
  color: #d70000;
}

.registerContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100%;
  padding-bottom: 0 !important;
}

.register {
  width: 100%;
  margin: auto;
  flex-shrink: 0;
  flex-grow: 1;
  justify-content: center;
  padding: 1.25rem 3.125rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
}

.title {
  margin-bottom: 0.625rem;
  text-align: center;
  width: 100%;
}

.description {
  text-align: center;
  width: 100%;
}

.registerFormContainer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding: 25px;
}

.registerForm {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 50%;
  margin: 0.5rem auto;
}

.inputSpan input:focus {
  outline: none;
}

.buttons {
  text-align: center;
}

.goBack {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 0 0 3rem;
  cursor: pointer;
}

.goBack svg {
  vertical-align: middle;
}

.address {
  background: white;
  border-radius: 5px;
  margin: 2px;
  padding: 2px;
  font-size: 14px;
}

.address ul {
  margin: 10px;
  padding: 0px;
}

.address ul li {
  margin: 0px;
  padding: 4px;
}

/* CSS used in page order */
.adrSelected {
  background: white;
  border-radius: 5px 0 0 5px;
  font-size: 14px;
}

.adrSelected ul {
  margin: 0 10px;
  padding: 0px;
}

.adrSelected ul li {
  margin: 0px;
  /* padding: 2px 4px; */
  padding: 4px;
}

/* Décale lergerment les button vers le bas et ajoute un margin-right */
.adjustPos {
  position: relative;
  top: 5px;
  margin-right: 5px;
}

.adjustPos2 {
  position: relative;
  top: 3px;
  margin-right: 5px;

  color: orange;
}

.addAddress {
  position: absolute;
  top: 10px;
  right: 10px;
}

.addAddressQuote {
  margin-top: -1.5vh;
  margin-left: 61vh;
  position: relative;
  z-index: 2;
}

.blockAddr {
  margin-top: -50px;
  padding-bottom: 12px;
  position: relative;
  z-index: 1;
}

.optionAddress {
  font-weight: bold;
  background-color: #d4cfce;
}

/* Popup warning lock client account */
.customUI {
  border: 1px solid black;
  border-radius: 5px;
  padding: 8px;
  height: 174px;
  width: 400px;
}

.customUI h2 {
  margin-top: 5px;
}
.customUI button {
  float: right;
  position: relative;

  font-family: sans-serif;

  margin: 0 0 0 10px;
  background-color: black;
  border: 1px solid black;
  padding: 8px;
  color: white;

  cursor: pointer;
}

.titleInfo {
  display: inline-block;
  margin-left: 5px;
  font-size: 24px;
  font-weight: 600;
}

.info {
  display: inline-block;
  font-size: 40px !important;
  position: relative;
  top: 11px;
}

.row {
  display: flex;
}

.columnRight {
  background: white;
  border-radius: 0 5px 5px 0;
  flex: 50%;
  position: relative;
}

.columnLeft {
  background: white;
  border-radius: 5px 0 0 5px;
  flex: 50%;
}

.noMargin {
  margin: 0px !important;
}
