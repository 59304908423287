.title{
    text-align: center;
}

.productDesignation{
  color: black;
  font-size: 13px;
  font-weight: 300;
  margin-bottom: 0;
  text-align: left;
  letter-spacing: 0.03rem;
  text-overflow: ellipsis;
  overflow: hidden;
 white-space: nowrap;
}

.phone{
    font-weight: bold;
}

.marginLeft{
    margin-left: 12%;
}

.popUp{
    background-color: #f7f7f7;
    width: 100vh;
}

.heigth{
    min-height: 85vh;
    max-height: 85vh;
}

.dialogTitle{
    text-align: center;
}

.selectedClient{
    margin-top: 2%;
    margin-left: 12.5%;
}

.bold{
    font-weight: bold;
}

.maxHeigth{
    height: 16vh;
    overflow-y: auto;
}

.msg{
    margin-top: 2%;
    color: red;
    font-size: 15px;
}

.center{
    text-align: center;
    text-decoration: none;
}

.black{
    hyphens: auto;
    margin: 5px 0;
    word-wrap: break-word;
    text-align: left;
    letter-spacing: 0.02rem;
    /* font-weight: 600; */
    font-weight: bold;
    color: black;
}

.new{
    hyphens: auto;
    margin: 5px 0;
    word-wrap: break-word;
    text-align: center;
    letter-spacing: 0.02rem;
    /* font-weight: 600; */
    font-weight: bold;
    color: white;
}

.actionHeight{
    text-align: center;
    position: relative;
    margin-top: 3%;
    height: 3vh;
    top:-35px;
}

.actionIcon{
    width: 100%;
    text-align: center;
    font-size: 90px;
    margin: auto; 
    margin-top: 5%;
}

.actionIcon svg {
    color: white;
    vertical-align: middle;
}

.cardBlack{
    border: solid 2px black;
    background: linear-gradient(to top, white 50%, black 50%);
    background-size: 100% 200%;
    background-position: top left;
    transition: all 0.3s ease-out;
    font-family: inherit;
    font-weight: bolder;
    text-transform: uppercase;
    padding: 10px 10px 10px 10px;
    cursor: pointer;
    color: white;
    font-size: 12px;
}

.cardBlack:hover svg{
    transition: all 0.3s ease-out;
    color: black !important;  
}
.cardBlack:hover .new{
    transition: all 0.3s ease-out;
    color: black !important;  
}

.cardBlack:hover{
    border: 2px solid black;
    background-position: bottom left;
    color: white;
}
