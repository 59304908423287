.title{
    text-align: center;
    font-size: 1.3rem;
    margin-bottom: 3%;
}

.gridContainer {
    height: 52vh;
    width: 100%;
    background: white;
  }