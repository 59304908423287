.animeTemplate {
  height: 89%;
  width: 400px;

  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  border: 1px solid rgb(87, 87, 87);

  color: white;
  padding: 15px;
  z-index: 10;

  position: fixed;
  right: 0px;
  top: 62px;
  transition: width 2s, height 2s, background-color 2s, transform 0.6s;
}

.animeHidden {
  composes: animeTemplate;
  background-color: rgb(253, 252, 252);
  transform: translateX(500px)
}
  
.animeShow {
  composes: animeTemplate;
  background-color: black;
}

.titleList {
  color: white;
  font-weight: 500;
  text-align: right;
  margin-bottom: 20px;
  padding: 5px;
  border-bottom: 1px solid white;
}

.listHandling {
  background-color:#eeedf0;
  border-radius: 0px;
  max-height: 86%;
  overflow-y: auto;
  padding: 0 4px;
}

.footerList {
  width: 100%;
  margin: auto;
  margin-top: 20px;
  padding-top: 15px;
  border-top: 1px solid white;
}

.notice{
  margin-bottom: 20px;
  padding: 10px;
  border-radius: 20px;
  border: 1px solid grey;
  background-color:#eeedf0;
  color: black;
  font-style: italic;
  font-size: small;

}

.infoTemplate {
  color: black;
  background-color: rgb(249, 249, 249);
  padding: 10px;
  border-radius: 5px;
  margin: 5px 0px;
  overflow-wrap: break-word;

}
.clientInfo {
  /* background-color: rgb(139, 139, 139); */
  background-color:rgb(218, 218, 218);
  color: rgb(2, 2, 2);
  border: 1px solid rgb(252, 251, 251);
  padding: 2px;
  /* border-top-left-radius: 15px;
  border-top-right-radius: 15px; */
  border-radius: 5px;
  margin-top: 5px;
  overflow-wrap: break-word;
  position: relative;
  top:5px;
  font-size: 0.9em;  
}

.boxInfo {
  composes: infoTemplate;
  border: 1px solid rgb(95, 105, 253);
}

.boxWarning {
  composes: infoTemplate;
  border: 1px solid rgb(252, 163, 61);
}

.boxError {
  composes: infoTemplate;
  border: 1px solid rgb(252, 82, 82);
}

.btnClearInfos {
  display: block;
  
  margin: auto;
  color: white;
  margin: auto;
  background: none;
  border: none;

}

.btnClearInfos:hover {
  cursor: pointer;
  text-decoration: underline;
}

.titleMsgTemplate {
  position: relative;
  top: 5px;
  margin-right: 5px;
  background-color: white;
  border-radius: 50%;
}

.info {
  composes: titleMsgTemplate;
  color: blue;
}

.warning {
  composes: titleMsgTemplate;
  color: orange;
}

.danger {
  composes: titleMsgTemplate;
  color: red;
}

.arrow {
  position: relative;
  top: 5px;
  margin-right: 5px;
  color: rgb(3, 3, 3);
  border-radius: 50%;
}

.linkX3 {
  display: inline-block;
  width: 100%;
  text-align: right;
  font-style: italic;
  font-size: small;
}