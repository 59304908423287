.searchContainer {
  width: 100%;
  margin: auto;
}

.searchContainer svg {
  vertical-align: middle;
  /* color: inherit; */
  margin-right: 10px;
  position: relative;
  bottom: 1px;
}

.selectCell {
  float: left;
  display: inline-block;
  width: 100%;
  height: 2.8rem;
}

.searchInput {
  display: inline-block;
  width: 92%;
  margin: auto;

  border: none;
  font-size: inherit;
  outline: none;
  font-family: inherit;
  min-height: 2.6rem;
  min-width: 92%;
  /* border-radius: 0.5rem; */
}

.anime {
  float: right;
  min-height: 3.5rem;
  display: inline-block;
  position: relative;

  top: 1px;
  right: 9px;
}
.searchIcon {
  position: relative;
  top: 10px;
  left: 5px;
}

.globalSearch {
  float: right;
  margin-left: 0px;
  margin-right: 0px;
  display: inline-block;
  border: 1px solid black;
  width: 48%;
}

.codeSearch {
  margin-left: 0px;
  margin-right: 10px;
  display: inline-block;
  border: 1px solid black;
  width: 48%;
}

.image {
  display: block;
  position: relative;
  width: 80px;
  height: 80px;
  background-color: #eeedf0;
}

.image img {
  position: absolute;
  bottom: 0;
  display: block;
  max-height: 90%;
  margin: auto;
  max-width: 90%;
  top: 0;
  left: 0;
  right: 0;
}

.productDesignation {
  color: black;
  font-size: 13px;
  font-weight: 300;
  padding-bottom: 10px;
  margin-bottom: 0;
  text-align: left;
  letter-spacing: 0.03rem;
}

.ref {
  hyphens: auto;
  margin: 5px 0;
  word-wrap: break-word;
  text-align: left;
  letter-spacing: 0.02rem;
  font-weight: 600;
}

.listWrapper,
.productWrapper {
  padding: 20px;
}
.cartTableRow {
  padding: 8px 0;
  text-align: center;
  border-bottom: 2px solid #f9f9f9;
}

.cartTableRow:hover {
  cursor: pointer;
  background-color: #e2e2e2;
}

.resultsContainer {
  background: white;
  width: 100%;
  position: relative;
  margin-top: 15px;
  display: flex;
  max-height: 60vh;
}

.resultsBox {
  overflow: auto;
  padding-left: 8px;
  padding-top: 0px;
  width: 100%;
}

.closeSearch {
  cursor: pointer;
  position: absolute;
  left: 91%;
  top: 3%;
}

.resultsContainer::before {
  content: "";
  position: absolute;
  top: -0.5rem;
  left: 1%;
  background-color: #fff;
  display: flex;
  width: 0.8125rem;
  height: 0.8125rem;
  transform: rotate(-135deg);
  border-right: 0.0625rem solid #e5e5e5;
  border-bottom: 0.0625rem solid #e5e5e5;
}

.none {
  display: none;
}

.stockAlert {
  font-size: smaller;
  padding-top: 5px;
  text-align: center;
}

.stockAlert svg {
  vertical-align: middle;
}

/* ------------------------------------------------------------------------- */
.debug {
  border: 1px solid red;
  background-color: gray;
}

.containerCatalog {
  display: flex;
}
.catalog {
  font-size: 12px;
  font-style: italic;
  color: gray;
  margin-right: 4px;
}

.posInToast {
  position: relative;
  top: 5px;
  margin-left: 6px;
  margin-right: 10px;
  color: rgb(104, 104, 255);
}
