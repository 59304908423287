.scrollTop {
  position: fixed;
  width: 100%;
  bottom: 20px;
  right: 20px;
  align-items: center;
  height: 20px;
  justify-content: center;
  z-index: 1000;
  cursor: pointer;
  animation: fadeIn 0.5s;
  transition: opacity 0.4s;
  display: flex;
  background: #cacaca;
  color: white;
  padding: 5px;
  border-radius: 5px;
}

.scrollTop:hover {
  background: linear-gradient(#1ba9aa, #1a8f9b);
}
