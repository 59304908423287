.txtCenter h4 {
    margin: 2px;
    text-align: center;
    color: black;
    font-size: 14px;    
  }

.txtCenter {
  text-align: center;
}

.marginL {
    margin-left: 30px;
}