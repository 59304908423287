.info {
    font-size: 14px;
    text-align:center;
    /* border: 1px solid red; */
    max-width: 160px;
}

.customPbWC {
    max-height: 100% !important;
    max-width: 100%;
    /* border: 1px solid red; */
    
}