.btnImport{
    margin-left: 5%;
}

.deleteIcon{
    position: relative;
    top: 5px;
    left: 5px;
    color: #e60000;
    font-size: 20px;
    cursor: pointer;
}

.adjustPos {
    position: relative;
    top: 6px;
  }

  .btnDownload{
    border: 2px solid black;
    background: linear-gradient(to top, white 50%, black 50%);
    background-size: 100% 200%;
    background-position: top left;
    transition: all 0.3s ease-out;
    font-family: inherit;
    font-weight: bolder;
    text-transform: uppercase;
    font-size: 10px;
    padding-bottom: 8px ;
    margin: 0;
    cursor: pointer;
    color: white;
  }
  
  .btnDownload:hover {
    border: 2px solid black;
    background-position: bottom left;
    color: black;
  }