@charset "UTF-8";

@font-face {
  font-family: "ITC Avant Garde Std title";
  src: url(./assets/fonts/ITCAvantGardeStd-Bk_BIOTECHBIOTECH.ttf)
    format("truetype");
  src: url(./assets/fonts/ITCAvantGardeStd-Bk_BIOTECHBIOTECH.otf)
    format("opentype");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "ITC Avant Garde Std text";
  src: url(./assets/fonts/ITCAvantGardeStd-Bk.ttf) format("truetype");
  src: url(./assets/fonts/ITCAvantGardeStd-Bk.eot) format("embedded-opentype"),
    url(./assets/fonts/ITCAvantGardeStd-Bk.eot?#iefix)
      format("embedded-opentype"),
    url(./assets/fonts/ITCAvantGardeStd-Bk.otf) format("opentype"),
    url(./assets/fonts/ITCAvantGardeStd-Bk.woff) format("woff"),
    url(./assets/fonts/ITCAvantGardeStd-Bk.svg#svgFontName) format("svg");
  font-weight: 400;
  font-style: normal;
}
body {
  margin: 0;
}

html,
body,
#root {
  font-family: "ITC Avant Garde Std text";
  height: 100%;
}

main,
footer {
  display: flex;
}

main {
  background: #f9f9f9;
  height: calc(100% - 80px);
  margin-left: calc(250px + 30px);
  overflow: overlay;
}

h1,
h2,
h3,
h4 {
  font-family: "ITC Avant Garde Std text";
}

.main-loader {
  justify-content: center;
  align-items: center;
  height: 25vh;
  margin-top: 25vh;
}

.customloader {
  display: flex;
  flex-direction: column;
  align-items: center;
  transform: rotate(90deg);
}

.customloader .row {
  display: flex;
}

.arrow-loader {
  width: 0;
  height: 0;
  margin: 0 calc(-12px / 2);
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  border-bottom: calc(12px * 1.8) solid black;
  animation: blink 1s infinite;
}

.down {
  transform: rotate(180deg);
}

.swiper-button-disabled svg {
  color: lightgray;
}

.swiper-button-disabled:hover svg {
  color: lightgray;
}

.outer-1 {
  animation-delay: calc(-1 / 18 * 1s);
}

.outer-2 {
  animation-delay: calc(-1 / 18 * 2s);
}

.outer-3 {
  animation-delay: calc(-1 / 18 * 3s);
}

.outer-4 {
  animation-delay: calc(-1 / 18 * 4s);
}

.outer-5 {
  animation-delay: calc(-1 / 18 * 5s);
}

.outer-6 {
  animation-delay: calc(-1 / 18 * 6s);
}

.outer-7 {
  animation-delay: calc(-1 / 18 * 7s);
}

.outer-8 {
  animation-delay: calc(-1 / 18 * 8s);
}

.outer-9 {
  animation-delay: calc(-1 / 18 * 9s);
}

.outer-10 {
  animation-delay: calc(-1 / 18 * 10s);
}

.outer-11 {
  animation-delay: calc(-1 / 18 * 11s);
}

.outer-12 {
  animation-delay: calc(-1 / 18 * 12s);
}

.outer-13 {
  animation-delay: calc(-1 / 18 * 13s);
}

.outer-14 {
  animation-delay: calc(-1 / 18 * 14s);
}

.outer-15 {
  animation-delay: calc(-1 / 18 * 15s);
}

.outer-16 {
  animation-delay: calc(-1 / 18 * 16s);
}

.outer-17 {
  animation-delay: calc(-1 / 18 * 17s);
}

.outer-18 {
  animation-delay: calc(-1 / 18 * 18s);
}

.inner-2 {
  animation-delay: calc(-1 / 6 * 2s);
}

.inner-3 {
  animation-delay: calc(-1 / 6 * 3s);
}

.inner-4 {
  animation-delay: calc(-1 / 6 * 4s);
}

.inner-5 {
  animation-delay: calc(-1 / 6 * 5s);
}

.inner-6 {
  animation-delay: calc(-1 / 6 * 6s);
}

.inner-1 {
  animation-delay: calc(-1 / 6 * 1s);
}

@keyframes blink {
  0% {
    opacity: 0.1;
  }
  30% {
    opacity: 1;
  }
  100% {
    opacity: 0.1;
  }
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  border-radius: 10px;

  background-color: #f5f5f5;
}
::-webkit-scrollbar {
  width: 12px;

  background-color: #f5f5f5;
}
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);

  background-color: #cccccc;
}
