.animeTemplate {
  height: 89%;
  width: 400px;

  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  border: 1px solid rgb(87, 87, 87);

  color: white;
  padding: 15px;
  z-index: 10;

  position: fixed;
  right: 0px;
  top: 62px;
  transition: width 2s, height 2s, background-color 2s, transform 0.6s;
}

.animeHidden {
  composes: animeTemplate;
  background-color: rgb(253, 252, 252);
  background-color: black;
  transform: translateX(500px);
}

.animeShow {
  composes: animeTemplate;
  background-color: #f1f0f3;
  color: black;
}

.titleList {
  color: white;
  font-weight: 500;
  text-align: right;
  margin-bottom: 20px;
  padding: 5px;
  border-bottom: 1px solid white;
}

.listHandling {
  background-color: #eeedf0;
  border-radius: 0px;
  max-height: 86%;
  overflow-y: auto;
  padding: 0 4px;
}

.footerList {
  width: 100%;
  margin: auto;
  margin-top: 20px;
  padding-top: 15px;
  border-top: 1px solid white;
}

.notice {
  margin-bottom: 20px;
  padding: 10px;
  border-radius: 20px;
  border: 1px solid grey;
  background-color: #eeedf0;
  color: black;
  font-style: italic;
  font-size: small;
}

.infoTemplate {
  color: black;
  background-color: rgb(249, 249, 249);
  padding: 10px;
  border-radius: 5px;
  margin: 5px 0px;
  overflow-wrap: break-word;
}
.clientInfo {
  /* background-color: rgb(139, 139, 139); */
  background-color: rgb(218, 218, 218);
  color: rgb(2, 2, 2);
  border: 1px solid rgb(252, 251, 251);
  padding: 2px;
  /* border-top-left-radius: 15px;
  border-top-right-radius: 15px; */
  border-radius: 5px;
  margin-top: 5px;
  overflow-wrap: break-word;
  position: relative;
  top: 5px;
  font-size: 0.9em;
}

.boxInfo {
  composes: infoTemplate;
  border: 1px solid rgb(95, 105, 253);
}

.boxWarning {
  composes: infoTemplate;
  border: 1px solid rgb(252, 163, 61);
}

.boxError {
  composes: infoTemplate;
  border: 1px solid rgb(252, 82, 82);
}

.btnClearInfos {
  display: block;

  margin: auto;
  color: white;
  margin: auto;
  background: none;
  border: none;
}

.btnClearInfos:hover {
  cursor: pointer;
  text-decoration: underline;
}

.titleMsgTemplate {
  position: relative;
  top: 5px;
  margin-right: 5px;
  background-color: white;
  border-radius: 50%;
}

.info {
  composes: titleMsgTemplate;
  color: blue;
}

.warning {
  composes: titleMsgTemplate;
  color: orange;
}

.danger {
  composes: titleMsgTemplate;
  color: red;
}

.arrow {
  position: relative;
  top: 5px;
  margin-right: 5px;
  color: rgb(3, 3, 3);
  border-radius: 50%;
}

.linkX3 {
  display: inline-block;
  width: 100%;
  text-align: right;
  font-style: italic;
  font-size: small;
}

.description {
  display: inline-block;
  font-style: italic;
  font-size: small;
  margin: 20px 0 12px 0;
}

.inputContainer {
  margin: 0 0 1.5rem;
  /* border: 1px solid green; */
  height: 80px;
}

.inpinputContainer input::placeholder {
  color: #757575;
  font-size: 0.8125rem;
  font-family: "ITC Avant Garde Std text";
  line-height: 1.3125rem;
  overflow: hidden;
  white-space: nowrap;
}

.inputSpan {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  font-family: "ITC Avant Garde Std text";
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 3.0625rem;
  padding: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: 0.0625rem solid #cecece;
  background-color: #fff;
  border-radius: 0.3125rem;
  position: relative;
  width: 100%;
}

.inputSpan input {
  font-family: "ITC Avant Garde Std text";
  border: 0;
  border-radius: 0.3125rem;
  font-size: 0.8125rem;
  line-height: 1.3125rem;
  width: 100%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background-color: transparent;
  outline: none;
}

.inputSpan input:focus {
  outline: none;
}

.errorText {
  margin-top: 0.1875rem;
  font-family: "ITC Avant Garde Std text";
  text-align: right;
  font-size: 0.8125rem;
  line-height: 1.3125rem;
  min-height: 0.8125rem;
  color: #d70000;
}

.lineHeight {
  margin-top: 0.1875rem;
  max-height: 1.3125rem;
  min-height: 0.8125rem;
  width: 100%;
  border: 1px solid red;
}

.substituteResult {
  margin-top: 0px;
  min-height: 50px;
  width: 98%;
  border-radius: 5px;
  background-color: white;
  border: 1px solid #cecece;
  padding-left: 8px;
  padding-top: 8px;
}

.btnSearch {
  margin-top: -40px;
}
