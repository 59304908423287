.header {
  background: black;
  color: white;
  width: 100%;
  border-radius: 5px;
  padding: 10px;
}

.header svg {
  vertical-align: middle;
  padding: 5px;
}

.singleLine {
  margin-top: 10px;
  margin-bottom: 3px;
  margin-left: 5px;
}

h4 {
  margin-top: 10px;
  margin-bottom: 3px;
  margin-left: 5px;
  text-align: left;
  color: black;
}

.infos {
  margin-top: 10px;
  margin-bottom: 3px;
  margin-left: 5px;
  text-align: left;
  color: black;
}