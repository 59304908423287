allChronologie.header {
  background: black;
  color: white;
  width: 100%;
  border-radius: 5px;
  padding: 10px;
}

.header svg {
  vertical-align: middle;
  padding: 5px;
}

#divFinalCommandeAffiché,
#divFinalDCICP,
#divFinalDCPIL {
  display: block;
}

#divFinalCommandeAffichéNone,
#divFinalDCICPNone,
#divFinalDCPILNone {
  display: none;
}

#divFinalCommandeAffiché a,
#divFinalDCICP a,
#divFinalDCPIL a {
  text-decoration: none;
}

.copyBtn {
  display: inline-block;
  position: relative;
  bottom: 10px;
  left: 0px;
}

.customSize {
  font-size: 90% !important;
}

.tooltipBtn {
  cursor: pointer;

  padding: 5px;
  width: 24px;
  height: 24px;

  z-index: 6;
}

.tooltipBtn:hover svg {
  color: #1a8f9b;
}

.tooltipBtn .tooltiptext {
  visibility: hidden;
  /* visibility: visible; */
  width: 220px;
  max-width: 250px;
  background-color: rgb(252, 242, 227);
  color: rgb(58, 58, 58);
  text-align: center;
  font-size: small;
  font-weight: lighter;

  border-radius: 6px;
  padding: 10px 0;

  /* Position the tooltip */
  position: relative;
  left: -230px;
  bottom: 30px;
}

.tooltipBtn:hover .tooltiptext {
  visibility: visible;
}

.boxHisto {
  padding: 5px 10px 0px 10px !important;
}

.boxHisto h2 {
  margin-bottom: 10px;
  margin-top: 6px;
  text-align: center;
  width: 100%;
}

/* Vient de storedCArd */

.title {
  margin-bottom: 10px;
  margin-top: 3rem;
  text-align: center;
  width: 100%;
}

.gridContainer {
  height: 71.2vh;
  width: 100%;
  background: white;
}

.MuiDataGrid-root .MuiDataGrid-columnHeaderTitle {
  font-weight: bold;
}

.delivered,
.notDelivered,
.unknown {
  display: flex;
  justify-content: center;
  align-items: center;
}

.delivered svg {
  color: #1ba9aa;
}

.noResult {
  width: 100%;
  text-align: center;
}

.see {
  color: black;
  cursor: pointer;
}

.buttonDiv {
  display: flex;
  justify-content: space-evenly;
}

.delete {
  color: red;
  cursor: pointer;
}

.noResult svg {
  vertical-align: bottom;
}

.notDelivered svg {
  color: orange;
}

.unknown svg {
  color: lightgrey;
}

.dialogTitle {
  font-weight: bold !important;
  display: inline-block;
  margin: auto;
}

.legend {
  margin-top: 22px;
  font-weight: 400;
  font-style: italic;
  color: rgb(58, 58, 58);
  font-size: 0.8em;
}

.tabDocument {
  min-width: 700px;
  min-height: 300px;
}

.grey {
  background-color: #f9f9f9;
  height: 42vh;
  width: 85vh;
}

.containerGrid {
  height: 25vh;
  width: 100%;
  margin-bottom: 30px;
}

.viewContainer {
  height: 25vh;
  width: 98%;
  margin: 0 auto;
  background-color: white;
}
