.clientData {
  padding: 8px 16px;
}

.clientData svg {
  vertical-align: middle;
}

.update {
  padding: 10px;
  padding-bottom: 0;
  font-size: smaller;
  font-weight: 400;
  cursor: pointer;
}

.update:hover {
  color: #1a8f9b;
}
.update svg {
  font-size: small;
  vertical-align: middle;
}

/* Décale lergerment les button vers le bas et ajoute un margin-right */
.adjustPos {
  position: relative;
  top: 5px;
  margin-right: 8px;
}

/* For block element with a tooltip */

.deleteBtn {
  position: fixed;
  top: 14px;
  right: 250px;
}

.saveBtn {
  position: fixed;
  top: 14px;
  right: 200px;
}

.bookBtn {
  position: fixed;
  top: 14px;
  right: 300px;
}

.SubstituteBtn {
  position: fixed;
  top: 14px;
  right: 350px;
}

.filterBtn {
  position: fixed;
  top: 14px;
  right: 400px;
}

.tooltipBtn {
  cursor: pointer;

  border: 1px solid black;
  padding: 5px;
  width: 24px;
  height: 24px;
  border-radius: 20px;

  z-index: 6;
}

.tooltipBtnActif {
  cursor: pointer;
  background-color: #1ba9aa;
  border: 1px solid black;
  padding: 5px;
  width: 24px;
  height: 24px;
  border-radius: 20px;

  z-index: 6;
}
.tooltipBtnActif svg {
  color: white;
}

.tooltipBtn:hover,
.tooltipBtnActif:hover {
  border: 1px solid black;
  background-color: black;
}

.tooltipBtn:hover svg {
  color: white;
}

.tooltipBtn .tooltiptext,
.tooltipBtnActif .tooltiptext {
  visibility: hidden;
  /* visibility: visible; */

  min-width: 200px;

  max-width: 250px;
  background-color: rgb(8, 8, 8);
  color: rgb(241, 241, 241);
  text-align: center;

  border-radius: 6px;
  padding: 10px;

  /* Position the tooltip */
  position: relative;
  right: 80px;
  top: 15px;
}

.tooltipBtn:hover .tooltiptext,
.tooltipBtnActif:hover .tooltiptext {
  visibility: visible;
}

.popupCmdOrg {
  border: 1px solid black;
  border-radius: 5px;
  padding: 8px;
  height: 90%;
  width: 90%;
}

.customUI_2 {
  height: 190px !important;
}

.customUI_2 p {
  margin-bottom: 40px;
}

.stackBook {
  height: 89%;
  /*  height: 300px; */
  width: 400px;

  border: 1px solid black;
  background-color: rgb(87, 87, 87);
  color: white;
  padding: 15px;
  z-index: 10;
  position: absolute;
  right: 0px;
  top: 65px;
  transition: width 2s, height 2s, background-color 2s, transform 2s;
}

.posInToast {
  position: relative;
  top: 5px;
  margin-left: 6px;
  margin-right: 10px;
  color: rgb(104, 104, 255);
}

/* css for bloc clientInfos ------------------------------------------------ */
.clientInfos {
  margin: 10px 10px 8px 10px;
  padding: 0px;
}

.clientInfos h3 {
  margin-top: 0px;
  margin-bottom: 8px;
  font-size: 16px;
}

.livraisonInfos {
  margin: 10px;
  padding: 0px;
}

.livraisonInfos h3 {
  margin-top: 0px;
  margin-bottom: 10px;
  font-size: 16px;
}

.pdR10 {
  padding-right: 10px;
}

.smileyContent {
  margin-top: 30px;
  padding-left: 10px;
  font-size: 90%;
  font-weight: 600;
  background-color: white;
  /* border:1px solid red; */
}

.reglement {
  margin-top: 10px;
  padding-left: 10px;
  font-size: 90%;
  font-weight: 600;
  color: red;
}

.mainContentExcel {
  position: relative;
  bottom: 5px;
  background-color: white;
  padding: 7px 10px;
  font-size: 14px;
  font-family: "ITC Avant Garde Std text";
}

.btnDownload {
  float: right;
}

.excelInput {
  margin-top: 3%;
}

.customUI {
  border: 1px solid black;
  border-radius: 5px;
  padding: 8px;
  min-height: 142px;
  width: 400px;
}
.customUI_2 {
  height: 198px !important;
}

.customUI h2 {
  margin-top: 5px;
}
.customUI button {
  float: right;
  position: relative;

  font-family: sans-serif;

  margin: 0 0 0 10px;
  background-color: black;
  border: 1px solid black;
  padding: 8px;
  color: white;

  cursor: pointer;
}

.titleInfo {
  display: inline-block;
  margin-left: 5px;
  font-size: 24px;
  font-weight: 600;
}

.info {
  display: inline-block;
  font-size: 40px !important;
  position: relative;
  top: 11px;
}
