.page {
  margin-top: 5vh;
  text-align: center;
  border: 1px solid;
  background-color: #FFFFFF;
  border-radius: 5px;
  border-color: lightgray;
}

.logo {
  margin-top: 10vh;
  max-height: 40vh;
  width: auto;
}

.slogan {
  margin-top: 10vh;
  margin-bottom: 10vh;
  font-size: 35px;
  font-weight: bold;
}