.customCard {
    width: 100%;
    height: 90%;
    max-width: 100%;
    border: 1px solid rgb(158, 158, 158);
    border-radius: 10px;
    padding: 0px;
}

.reduceContent {
  width: 90%;
  height: 90%;
  margin:auto;
}
.data {
  width: 100%;
  height: 100%;
  padding: 10px;
  font-size: 13px;
}

.accordionContainer {
  margin: 0px !important;
  padding-top: 3px !important;
}

.customAccordion {
  padding-bottom: 13px;
}

.typo_01{
  padding-top: 9px;
  font-style: italic !important;
  
}

.typo_01:hover{
  color: #1ba9aa;
  text-decoration: underline;
}

.relativePos {
    position: relative;
    bottom: 20px;
}

.relativePos02 {
  position: relative;
  top: 8px;
}

.relativePos03 {
  position: relative;
  top:8px
}

.infoToday {
    font-size: 14px;
    text-align:center;
}

.infoNumberToday {
    composes: infoToday
}

.gridContainer {
    /*  height: 80%; */
    height: 56vh;
    width: 100%;
    background: white;
    font-family: "inherit";
}

.customDatePicker {
  background-color: white;

}
.customDatePicker div input {
  font-size: 0.7em !important;
  max-width: 80px;
}

.title {
  margin-bottom: 20px;
  margin-top: 10px;
  text-align: center;
  width: 100%;
}

.customFieldset{
  height:calc(100% - 20px);
  border-radius: 10px 10px 0px 0px;
  background-color: #eeedeb;
  border: 1px solid #e0e0e0;
}

.pLegend {
  background-color: white;
  border: 1px solid #e0e0e0;
  border-radius: 15px;
  padding: 5px 10px;
}

.selection{
  padding: 0px !important;
  background: rgba(64, 105, 110, 1.0);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);  
  
  color: white;
  font-size: 1.1em;
}

.blurryText{
  height: 80px;
  padding: 8px 30px !important;
  text-shadow: 1px 1px 2px black;
  /* border: 1px solid red; */

  display:table-cell;
  vertical-align: middle;
}

.blurryText span {
  display: inline-block;
  margin-left: 4px;
  margin-right: 4px;
  font-weight: 800;
}

.subTitle {
  margin-top: 4px;
  font-size: 0.8em;
}

.customPos {
  position: relative;
  bottom: 70px;
}

.contentSpace {
  margin-top: 30px !important;
  /* border: 1px solid red; */
}

.customMargin {
  margin:auto;
  height:90%;
  width:93%;
}

.labelArray {
  width: 99.8%;
  margin: auto;
  margin-bottom: 1px;

  border: 1px solid #dbdbdb;
  border-bottom: none;
  padding: 4px 0px;
  /* padding-left: 20px; */

  border-radius: 10px 10px 4px 4px;
  background-color:#eeedeb;
  text-align: center;
}

.btn01 {
  /* background-color: #297ad4 !important; */
  /* color: white !important; */
  text-transform: none !important;
}

.btn01:hover {
  /* background-color: #1565c0 !important; */
  color: rgb(12, 120, 221) !important;
}

.refreshPos_01 {
  margin-left: 20px !important;

  position: relative;
  top:6px;
}

.refreshPos_02 {
  margin-left: 20px !important;

  position: relative;
  top:46px;

}

.chartButtonL {
  display: flex;
  justify-content: flex-start;
  /* border: 1px solid red; */
}

.chartButtonR {
  composes: chartButtonL;
  justify-content: flex-end;
}


.txtCenter{
  display: flex;
  align-items: center;
  justify-content: center;
  /* text-align: center;
  vertical-align: bottom; */
}

.txtBold {
  font-weight: 800;
}

.txtDarkGreen {
  color: rgb(23, 70, 35);
}

.customTools {
  border-bottom: 1px solid #d7d7d7;
}

