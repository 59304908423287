.title{
    text-align: center;
}

.solde{
    font-weight: bold;
    margin-left: 2%;
    margin-bottom: 3%;
}

.inputContainer {
    margin: 0 0 1.5rem;
  }
  
  .twoFieldsRow .inputContainer {
    width: 45%;
  }
  .inputContainer input::placeholder {
    color: #757575;
    font-size: 0.8125rem;
    font-family: "ITC Avant Garde Std text";
    line-height: 1.3125rem;
    overflow: hidden;
    white-space: nowrap;
  }
  
  .twoFieldsRow {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
  }
  
  .select {
    border: none;
    background: white;
    overflow: auto;
    width: 100%;
    outline: none;
  }
  .inputSpan {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    font-family: "ITC Avant Garde Std text";
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: 3.0625rem;
    padding: 0;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border: 0.0625rem solid #cecece;
    background-color: #fff;
    border-radius: 0.3125rem;
    position: relative;
    width: 100%;
  }
  
  .inputSpan input {
    font-family: "ITC Avant Garde Std text";
    border: 0;
    border-radius: 0.3125rem;
    font-size: 0.8125rem;
    line-height: 1.3125rem;
    width: 100%;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    background-color: transparent;
    outline: none;
  }

  .formContainer {
    margin-top: 2%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 0 !important;
  }

  .settlementFormContainer{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    justify-content: center;
    width: 60%;
    background-color: white;
    padding-top: 2%;
    padding-bottom: 5%;
    border: 1px solid #cecece;
  }

  .form {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 60%;
    margin: 0.5rem auto;
  }

  .buttons {
    text-align: center;
  }

  .label{
    font-size: 12px;
    width: 100%;
  }

  .error{
    color: red;
    margin-left: 5px;
    font-size: 12px;
    font-style: italic;
  }

  .radioInput{
    margin-left: 5%;
  }

  .ml{
    margin-left: 2%;
  }