.popUp {
    width: 100vh;
    padding-bottom: 3%;
}

.center {
    font-size: 23px;
    text-align: center;
    padding: 25px;
}

.form {
    width: 80%;
    margin: auto;
}

.checks {
    margin-top: 20px;
    margin-left: 5px;
}

.button {
    text-align: center;
}

.inputEmailList {
    height: 60px;
    border: 1px solid blue;
}
