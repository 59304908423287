.container {
    width: 83%;
    height: 100%;
    background: black;
    color: white;
    position: absolute;
    top: 0;
    display: flex;
    align-items: center;
    align-content: center;
    flex-direction: column;
}

.code,
.text {
    display: flex;
    justify-content: center;
    padding: 25px;
}

.button {
    /* position: relative;
    bottom: 50px; */

    display: flex;
    justify-content: center;
    border: 1px solid white;
    padding: 0;
    margin: 0;
}

.button button {
    margin: 0 !important;
}

.code {
    font-size: 70px;
}

.logo {
    position: relative;
    top:50px;
    display: flex;
    justify-content: center;
}

.logo img {
    width: 200px;
    max-width: 300px;
}

.noWifi {
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    text-align: center;
    font-size: 20px;
    background-color: black;
    height: 50%;
}

.contentnoWifi h4 {
    color: white;
}