.none {
  display: none !important;
  color: orange;
}

.bold {
  font-weight: 600;
}

.title {
  margin-bottom: 10px;
  margin-top: 3rem;
  text-align: center;
  width: 100%;
}

.gridContainer {
  /*  height: 80%; */
  height: 80vh;
  width: 100%;
  background: white;
  font-family: "inherit";
}

.tableHeader {
  font-weight: bold;
  color: black;
}

.invoiceNumber {
  font-weight: bold;
}

.MuiDataGrid-root .MuiDataGrid-columnHeaderTitle {
  font-weight: bold;
}

.delivered,
.notDelivered,
.unknown {
  display: flex;
  justify-content: center;
  align-items: center;
}

.delivered svg {
  color: #1ba9aa;
}

.noResult {
  width: 100%;
  text-align: center;
}

.see {
  color: black;
  cursor: pointer;
}

.buttonDiv {
  display: flex;
  justify-content: space-evenly;
}

.delete {
  color: red;
  cursor: pointer;
}

.noResult svg {
  vertical-align: bottom;
}

.notDelivered svg {
  color: orange;
}

.unknown svg {
  color: lightgrey;
}

.boxOrder {
  padding: 6px 10px 0px 10px !important;
}

.boxOrder h2 {
  margin-bottom: 10px;
  margin-top: 20px;
  text-align: center;
  width: 100%;
}

.lockOrder {
  color: black;
  font-size: 12px !important;
}

.customUI2 {
  border: 1px solid black;
  border-radius: 5px;
  padding: 8px;
  height: 250px;
  width: 400px;
}
.customUI2 h2 {
  margin-top: 5px;
}
.customUI2 button {
  float: right;
  position: relative;

  font-family: sans-serif;

  margin: 0 0 0 10px;
  background-color: black;
  border: 1px solid black;
  padding: 8px;
  color: white;

  cursor: pointer;
}

.customUI {
  border: 1px solid black;
  border-radius: 5px;
  padding: 8px;
  height: 200px;
  width: 400px;
}

.customUI h2 {
  margin-top: 5px;
}
.customUI button {
  float: right;
  position: relative;

  font-family: sans-serif;

  margin: 0 0 0 10px;
  background-color: black;
  border: 1px solid black;
  padding: 8px;
  color: white;

  cursor: pointer;
}

.titleWarning {
  display: inline-block;
  margin-left: 5px;
  font-size: 24px;
  font-weight: 600;
}

.warning {
  display: inline-block;
  font-size: 40px !important;
  position: relative;
  top: 6px;
}

.beatsWarning {
  display: inline-block;

  color: rgb(3, 3, 3);
  animation: fadeIn linear 2s infinite;
  -webkit-animation: fadeIn linear 2s infinite;
  -moz-animation: fadeIn linear 2s infinite;
  -o-animation: fadeIn linear 2s infinite;
  -ms-animation: fadeIn linear 2s infinite;
}

.lineHeigth {
  line-height: 15px;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
