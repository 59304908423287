.clientInfos {
  margin: 20px 10px 8px 10px;
  padding: 0px;
}

.titleBlock {
  width: 100%;
}

.dataClient {
  background-color: white;
  display: flex;
  border-radius: 5px;
  padding: 10px;
  justify-content: space-around;
}
.client {
  text-align: center;
  width: 30%;
  border-right: 1px solid #e6e6e6;
}
.solde {
  width: 70%;
}

.blockInput {
  margin-top: 10px;
  background-color: white;
  border-radius: 5px;
  padding: 5px;
}

.clientInfos h3 {
  margin-top: 0px;
  margin-bottom: 8px;
  font-size: 16px;
}

/* Décale lergerment les button vers le bas et ajoute un margin-right */
.adjustPos {
  position: relative;
  top: 5px;
  margin-right: 8px;
}

.boldGreen {
  font-weight: bold;
  color: #00b300;
  text-align: center;
}

.boldRed {
  font-weight: bold;
  color: #ff0000;
  text-align: center;
}

.txtCenter {
  text-align: center;
}

.searchIcon {
  min-height: 3.5rem;
  display: flex;
  align-items: center;
}

.searchContainer svg {
  vertical-align: middle;
  color: inherit;
}

.title {
  text-align: center;
}

.selectCell {
  position: relative;
  display: flex;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  min-height: 3.4375rem;
  align-items: center;
  width: 100%;
  background: white;
  border-radius: 0.5rem;
}

.searchInput {
  border: none;
  font-size: inherit;
  outline: none;
  font-family: inherit;
  min-height: 3.4375rem;
  width: 100%;
  border-radius: 0.5rem;
}

.resultsContainer {
  background: white;
  width: 100%;
  position: relative;
  margin-top: 15px;
  display: flex;
  max-height: 60vh;
}

.none {
  display: none;
}

.closeSearch {
  cursor: pointer;
  position: absolute;
  left: 95%;
  top: 3%;
}

.resultsBox {
  overflow: auto;
  padding-left: 4rem;
  padding-top: 3.5rem;
  width: 100%;
}

.cartTableRow {
  padding: 20px 0;
  text-align: center;
}

.ref {
  hyphens: auto;
  margin: 5px 0;
  word-wrap: break-word;
  text-align: left;
  letter-spacing: 0.02rem;
  /* font-weight: 600; */
  font-weight: 100;
  font-size: smaller;
  color: green;
}

.productDesignation {
  color: black;
  font-size: 13px;
  font-weight: 300;
  padding-bottom: 10px;
  margin-bottom: 0;
  text-align: left;
  letter-spacing: 0.03rem;
}

.blockWhite {
  background-color: white;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 10px;
}

.tooltipBtn {
  cursor: pointer;
  border: 1px solid black;
  padding: 5px;
  width: 24px;
  height: 24px;
  border-radius: 20px;
  z-index: 6;
}

.tooltipBtn:hover {
  border: 1px solid black;
  background-color: black;
}

.tooltipBtn:hover svg {
  color: white;
}

.tooltipBtn .tooltiptext {
  visibility: hidden;
  /* visibility: visible; */
  min-width: 200px;
  max-width: 250px;
  background-color: rgb(8, 8, 8);
  color: rgb(241, 241, 241);
  text-align: center;
  border-radius: 6px;
  padding: 10px;
  /* Position the tooltip */
  position: relative;
  right: 80px;
  top: 15px;
}

.tooltipBtn:hover .tooltiptext {
  visibility: visible;
}

.deleteBtn {
  position: fixed;
  top: 14px;
  right: 200px;
}

.saveBtn {
  position: fixed;
  top: 14px;
  right: 250px;
}

.customUI {
  border: 1px solid black;
  border-radius: 5px;
  padding: 8px;
  height: 142px;
  width: 300px;
}

.customUI h2 {
  margin-top: 5px;
}
.customUI button {
  float: right;
  position: relative;

  font-family: sans-serif;

  margin: 0 0 0 10px;
  background-color: black;
  border: 1px solid black;
  padding: 8px;
  color: white;

  cursor: pointer;
}
