.contentWrap{
  font-size: 16px;
  padding: 0px !important;
}

.goBack {
  position: relative;
  bottom:10px;
  cursor: pointer;
  margin: 0px;
  padding: 0px;
  font-size: 14px;
}

.goBack svg {
  position: relative;
  top:1px;
  font-size: 14px;
}

.clientInfos{
  margin:0px 10px;
  padding: 0px;
}

.clientInfos h3 {
  position: relative;
  bottom: 9px;
  margin: 0px;
  margin-bottom:2px;
  font-size: 16px;
}

.livraisonInfos {
  position: relative;
  bottom: 9px;
  margin-left: 12px;
}

.livraisonInfos h3 {
  margin-top: 0px;
  margin-bottom: 10px;
  font-size: 16px;
}

.itemRef {
  padding: 15px;
  font-weight: bold;
}

.topRow {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-content: flex-start;
  font-size: 14px;
}

.topRow button {
  margin-top: 0;
}

.title {
  margin-bottom: 3rem;
  margin-top: 4rem;
  text-align: start;
  width: 100%;
}

.stockAlert {
  font-size: smaller;
  padding-top: 5px;
  text-align: center;
}

.stockAlert svg {
  vertical-align: middle;
}

.image {
  display: block;
  position: relative;
  width: 80px;
  height: 80px;
  background-color: #eeedf0;
}

.image img {
  position: absolute;
  bottom: 0;
  display: block;
  max-height: 90%;
  margin: auto;
  max-width: 90%;
  top: 0;
  left: 0;
  right: 0;
}

.deleteButton {
  outline: none;
  background: transparent;
  border: none;
}
.productDesignation {
  color: black;
  font-size: 13px;
  font-weight: bold;
  padding-bottom: 10px;
  margin-bottom: 0;
}

.ref {
  hyphens: auto;
  margin: 5px 0;
  word-wrap: break-word;
}

.deleteButton {
  outline: none;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  color: #9d9d9c;
}

.listWrapper,
.productWrapper {
  padding: 20px;
}

.displayNone {
  display: none;
}

.price {
  font-weight: normal;
}

.addWishlistIcon svg {
  z-index: 100;
}



.cartTableHeader {
  border-bottom: 1px solid rgba(112, 112, 112, 0.2);
  padding: 20px 0;
  text-align: center;
}

.cartTableRow {
  padding: 20px 0;
  text-align: center;
}

.align-left {
  text-align: left;
}

.total {
  text-align: center;
}

.cartTotalContainer {
  padding: 20px 0;
  border-top: 1px solid rgba(112, 112, 112, 0.2);
  font-weight: bold;
}

.totalNote {
  font-style: italic;
  font-size: smaller;
  margin-top: 3rem;
}

.address {
  background: white;
  border-radius: 5px;
  margin: 0;
  padding: 8px 0px;
}

.address ul{
  margin: 0;
  padding: 4px 12px;
  font-size: 14px;
}

.address ul li {
  padding: 4px;
}

.rowButtonSaveBasket {
  position: fixed;
  padding: 0px;
  margin: 0px;
  width: 100%;
  height: 100px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  }

.leftButtonsaveBasket {
  grid-area: 1 / 1 / 2 / 3;
}

.buttonSaveBasket {
  grid-area: 1 / 3 / 2 / 4;
}

/* Décale lergerment les button vers le bas et ajoute un margin-right */
.adjustPos {
  position: relative;
  top: 5px;
  margin-right: 8px;
}

.adjustPos2 {
  position: relative;
  top: 5px;
  right:2px;
  margin-right: 7px;
}

/* For block element with a tooltip */ 
.deleteBtn {
  position: fixed;
  top: 14px;
  right: 250px;
}

.saveBtn {
  position: fixed;
  top: 14px;
  right: 200px;
}

.bookBtn {
  position: fixed;
  top: 14px;
  right: 300px;
}

.tooltipBtn {
  cursor: pointer;

  border: 1px solid black;
  padding: 5px;
  width: 24px;
  height: 24px;
  border-radius: 20px;

  z-index: 6;
}

.tooltipBtn:hover {
  border: 1px solid black;
  background-color: black; 
}

.tooltipBtn:hover svg {
  color: white;
} 

.tooltipBtn .tooltiptext {
  visibility: hidden;
  /* visibility: visible; */
  width: 200px;
  background-color: rgb(8, 8, 8);
  color: rgb(241, 241, 241);
  text-align: center;
  
  border-radius: 6px;
  padding: 10px 0;

  /* Position the tooltip */
  position: relative;
  right: 80px;
  top: 15px;
}

.tooltipBtn:hover .tooltiptext {
  visibility: visible;
}

.customUI {
  border: 1px solid black;
  border-radius: 5px;
  padding: 8px;
  height: 142px;
  width: 300px;
}

.customUI h2{
  margin-top: 5px;
}
.customUI button {
  float: right;
  position: relative;

  font-family: sans-serif;

  margin: 0 0 0 10px;
  background-color: black;
  border: 1px solid black;
  padding: 8px;
  color: white;

  cursor: pointer;
}

.smileyContent{
  margin-top:30px;
  padding-left: 10px;
  font-size: 90%;
  font-weight: 600;
  background-color: white;
  /* border:1px solid red; */

}

.reglement {
  margin-top: 10px;
  padding-left: 10px;
  font-size: 90%;
  font-weight: 600;
  color: red;
}

.reglement_info{
  composes: reglement;
  color: black;
}


