.itemRef {
  padding: 15px;
  font-weight: bold;
}

.topRow {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-content: flex-start;
}

.topRow button {
  margin-top: 0;
}

.title {
  margin-bottom: 3rem;
  margin-top: 4rem;
  text-align: start;
  width: 100%;
}

.stockAlert {
  font-size: smaller;
  padding-top: 5px;
  text-align: center;
}

.stockAlert svg {
  vertical-align: middle;
  position: relative;
  right: 5px;
  bottom: 2px;
}

.image {
  display: block;
  position: relative;
  width: 150px;
  height: 150px;
  background-color: #eeedf0;
}

.image img {
  position: absolute;
  bottom: 0;
  display: block;
  max-height: 90%;
  margin: auto;
  max-width: 90%;
  top: 0;
  left: 0;
  right: 0;
}

.blockImg {
  /* padding: 10px; */
  width: 70%;
  min-height: 30px;
  max-height: 70px;
  background-color: #eeedf0;
  position: relative;
  vertical-align: top;
}

.blockImg img {
  display: block;
  max-height: 70px;
  max-width: 70%;
  margin: auto;
}

.deleteButton {
  outline: none;
  background: transparent;
  border: none;
}
.productDesignation {
  color: black;
  font-size: 13px;
  padding-bottom: 10px;
  margin-bottom: 0;
}

.tableContainer {
  background: white;
  border-radius: 5px;
  margin: 0px;
  margin-top: 10px;
  border: solid 2px white;
}

.customInput {
  display: flex;
  width: 100%;
  align-items: center;
}

.add:hover {
  color: #1a8f9b;
  cursor: pointer;
}

.add svg {
  vertical-align: middle;
}

.customInput input {
  width: inherit;
  border: none;
  font-size: inherit;
  font-family: inherit;
  text-align: center;
}

.customInput input:focus {
  outline: solid 1px #9d9d9c;
  border-radius: 1px;
  box-shadow: 0px 0px 3px 0px;
}

.ref {
  hyphens: auto;
  margin: 5px 0;
  word-wrap: break-word;
}

.deleteButton {
  outline: none;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  color: #9d9d9c;
}

.listWrapper,
.productWrapper {
  padding: 20px;
}

.displayNone {
  display: none;
}

.price {
  font-weight: normal;
}

.addWishlistIcon svg {
  z-index: 100;
}

.contactDiv svg,
.goBack svg {
  vertical-align: middle;
}

.cartTableHeader {
  border-bottom: 1px solid rgba(112, 112, 112, 0.2);
  padding: 3px 6px;
  text-align: center;
}

.cartTableRow {
  padding: 6px;
  text-align: left;
  width: 100%;
  background: #f9f9f9;
  border-bottom: solid 2px white;
}

.txtCenter {
  text-align: center;
}

.total {
  text-align: center;
}

.cartTotalContainer {
  padding: 20px 0;
  border-top: 1px solid rgba(112, 112, 112, 0.2);
  font-weight: bold;
}

.totalNote {
  font-style: italic;
  font-size: smaller;
  margin-top: 3rem;
}

.goBack {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}
.bottomButtons {
  display: flex;
  width: 50%;
  flex-direction: row;
  justify-content: space-evenly;
  padding: 25px;
}
.goBack svg {
  vertical-align: middle;
}
