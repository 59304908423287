.alignL {
  margin-top: 0px;
  padding-left: 4px;
  text-align: left;
}

.alignR {
  text-align: right;
}

.alignRTop {
  display: inline-block;
  position: relative;
  bottom: 23px;
  text-align: right;
}

.posTop {
  display: inline-block;
  position: relative;
  bottom: 23px;
}

.itemRef {
  padding: 15px;
  font-weight: bold;
}

.topRow {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-content: flex-start;
}

.topRow button {
  margin-top: 0;
}

.title {
  margin-bottom: 3rem;
  margin-top: 4rem;
  text-align: start;
  width: 100%;
}

.bottomButtons {
  display: flex;
  width: 50%;
  flex-direction: row;
  justify-content: space-evenly;
  padding: 25px;
}

.cantRunOrder {
  display: flex;
  width: 50%;
  flex-direction: row;
  justify-content: space-evenly;
  padding: 25px;
}

.cantRunOrder:hover {
}

.address {
  background: white;
  border-radius: 5px;
  margin: 32px;
  padding: 32px;
}

.stockAlert {
  font-size: smaller;
  padding: 0px;
  text-align: center;
}

.stockAlert svg {
  vertical-align: middle;
}

.offreAlert {
  font-size: smaller;
  padding: 0px 0;
  /* text-align: center; */
}

.offreAlert svg {
  vertical-align: middle;
}

.image {
  display: block;
  position: relative;
  width: 80px;
  height: 80px;
  background-color: #eeedf0;
}

.blockImg {
  /* padding: 10px; */
  width: 100%;
  height: 100%;
  min-height: 30px;
  max-width: 126px;
  background-color: #eeedf0;
  align-items: center;
  margin-left: 2px;
  margin-right: 0px;
  padding-top: 2px;
}

.blockImg img {
  display: flex;
  max-height: 70px;
  max-width: 96%;
  margin: auto;
}

.customInputTop {
  height: 100%;
  display: flex;
  flex-direction: row;
  /* justify-content: space-evenly; */
  align-items: center;
}

.customInputTopComm {
  height: 100%;

  /* justify-content: space-evenly; */
}

.customInputTopComm input {
  width: 90%;
  border: none;
  font-size: inherit;
  font-family: inherit;
  text-align: left;
  margin-left: 10px;
  display: inline-block;
}

.customInputTopComm input:focus {
  outline: solid 1px #9d9d9c;
  border-radius: 1px;
  box-shadow: 0px 0px 3px 0px;
}

.customInput {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: flex-top;
}

.customInput input {
  width: 70%;
  border: none;
  font-size: inherit;
  font-family: inherit;
  text-align: right;
}

.customInput input:focus {
  outline: solid 1px #9d9d9c;
  border-radius: 1px;
  box-shadow: 0px 0px 3px 0px;
}

.image img {
  position: absolute;
  bottom: 0;
  display: block;
  max-height: 90%;
  margin: auto;
  max-width: 90%;
  top: 0;
  left: 0;
  right: 0;
}

.deleteButton {
  outline: none;
  background: transparent;
  border: none;
}
.productDesignation {
  color: black;
  font-size: 13px;
  /* font-weight: bold; */
  padding-bottom: 10px;
  margin-bottom: 0;
}

.ref {
  hyphens: auto;
  margin: 0px 0px 5px 0px;
  word-wrap: break-word;
}

.deleteButton {
  outline: none;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  color: #9d9d9c;
}

.listWrapper,
.productWrapper {
  padding: 20px;
}

.displayNone {
  display: none;
}

.price {
  font-weight: normal;
}

.addWishlistIcon svg {
  z-index: 100;
}

.contactDiv svg,
.goBack svg {
  vertical-align: middle;
}

.cartTableHeader {
  border-bottom: 1px solid rgba(112, 112, 112, 0.2);
  padding: 3px 6px;
  text-align: center;
  background-color: white;
  margin-top: 20px;
}

.cartTableRow {
  padding: 2px;
  text-align: center;
  border: 2px solid white;
}

.total {
  text-align: center;
}

.totalQte {
  text-align: center;
  border-right: 1px solid rgb(221, 221, 221);
}

.cartTotalContainer {
  padding: 20px 0;
  border-top: 1px solid rgba(112, 112, 112, 0.2);
  font-weight: bold;
}

.totalNote {
  font-style: italic;
  font-size: smaller;
  margin-top: 3rem;
}

.goBack {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}

.goBack svg {
  vertical-align: middle;
}

#AlertValueNegative {
  display: none;
}

.posInToast {
  position: relative;
  top: 6px;
  margin-left: 6px;
}

.justificationField {
  display: block;
}

.justificationFieldNone {
  display: none;
}

.pdR10 {
  padding-right: 10px;
}

.customUI {
  border: 1px solid black;
  border-radius: 5px;
  padding: 8px;
  height: 180px;
  width: 300px;
}

.customUI h2 {
  margin-top: 5px;
}
.customUI button {
  float: right;
  position: relative;

  font-family: sans-serif;

  margin: 0 0 0 10px;
  background-color: black;
  border: 1px solid black;
  padding: 8px;
  color: white;

  cursor: pointer;
}

.containerCatalog {
  display: flex;
  margin-bottom: 10px;
}

.catalog {
  font-size: 12px;
  font-style: italic;
  color: gray;
  margin-right: 4px;
}

.grey {
  background-color: #f9f9f9;
}
