.gridContainer {
  height: 65vh;
  width: 100%;
  background: white;
}

.selectedClient{
  margin-top: 15px;
}

.center{
  font-size: 23px;
  text-align: center;
  padding: 25px;
}

.eye{
  cursor: pointer;
}

.viewContainer{
  height: 40vh;
  width: 100%;
  background: white;
}

.title{
  text-align: center;
  margin-bottom: 2%;
}

.textCenter{
  text-align: center;
  color: rgb(0, 0, 0);
}

.top{
  margin-top: 3%;
}

.popUp{
  width: 100vh;
  padding-bottom: 3%;
}

.client{
  margin-left:10%;
}

.adrLivr{
  float: right;
  padding-right: 20%;
}

.viewContainer{
  width: 90%;
  margin: auto;
}

.grey{
 background-color: #f9f9f9;
}