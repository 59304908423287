.title {
  margin-bottom: 10px;
  margin-top: 3rem;
  text-align: center;
  width: 100%;
}

.gridContainer {
  height: 80vh;
  width: 100%;
  background: white;
}

.MuiDataGrid-root .MuiDataGrid-columnHeaderTitle {
  font-weight: bold;
}

.delivered,
.notDelivered,
.unknown {
  display: flex;
  justify-content: center;
  align-items: center;
}

.delivered svg {
  color: #1ba9aa;
}

.noResult {
  width: 100%;
  text-align: center;
}

.see {
  color: black;
  cursor: pointer;
}

.buttonDiv {
  display: flex;
  justify-content: space-evenly;
}

.delete {
  color: red;
  cursor: pointer;
}

.noResult svg {
  vertical-align: bottom;
}

.notDelivered svg {
  color: orange;
}

.unknown svg {
  color: lightgrey;
}

.titleClient {
  margin-bottom: 10px;
  margin-top: 10px;
  text-align: left;
  width: 100%;
  font-weight: 600;
}

.orderList{
  padding:6px 10px 0px 10px !important;
}

.orderList h2 {
  margin-bottom: 10px;
  margin-top: 20px;
  text-align: center;
  width: 100%;
}

.selectedClient {
  margin-bottom: 10px;
  margin-top: 10px;
  font-weight: 600;
}

.selectedClientLeft {
  float: left;
  width: 49%;
}

.selectedClientRight {
  float: right;
  width: 49%;
  padding: 8px 0px 0px 21px !important;
}

.selectMailFacture {
  border: solid 2px black;
  background-size: 100% 200%;
  background-position: top left;
  font-family: inherit;
  font-weight: bolder;
  text-transform: uppercase;
  padding: 14px 20px 9px 20px;
  margin: 0px auto 0;
  cursor: pointer;
  color: black;
  float: right;
}