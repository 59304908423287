.bannerButtonDark {
  border: 2px solid black;
  background: linear-gradient(to top, black 50%, white 50%);
  background-size: 100% 200%;
  background-position: top left;
  transition: all 0.3s ease-out;
  font-family: inherit;
  font-weight: bolder;
  text-transform: uppercase;
  padding: 15px 20px 10px 20px;
  margin: 30px auto 0;
  cursor: pointer;
  color: black;
}

.bannerButtonDark:hover {
  border: 2px solid black;
  background-position: bottom left;
  color: white;
}

.bannerButtonLight {
  border: solid 2px black;
  background: linear-gradient(to top, white 50%, black 50%);
  background-size: 100% 200%;
  background-position: top left;
  transition: all 0.3s ease-out;
  font-family: inherit;
  font-weight: bolder;
  text-transform: uppercase;
  padding: 10px 10px 10px 10px;
  margin: 30px auto 0;
  cursor: pointer;
  color: white;
  font-size: 12px;
}

.bannerButtonLight:hover {
  border: 2px solid black;
  background-position: bottom left;
  color: black;
}

.bannerButtonLittle {
  border: 2px solid black;
  background: linear-gradient(to top, white 50%, black 50%);
  background-size: 100% 200%;
  background-position: top left;
  transition: all 0.3s ease-out;
  font-family: inherit;
  font-weight: bolder;
  text-transform: uppercase;
  font-size: 12px;
  padding: 5px 10px 5px 10px;
  margin: 0;
  cursor: pointer;
  color: white;
}

.bannerButtonLittle:hover {
  border: 2px solid black;
  background-position: bottom left;
  color: black;
}

.bannerButtonDisabled {
  border: 2px solid rgb(141, 140, 140);
  background: linear-gradient(to top, white 50%, grey 50%);
  background-size: 100% 200%;
  background-position: top left;
  transition: all 0.3s ease-out;
  font-family: inherit;
  font-weight: bolder;
  text-transform: uppercase;
  font-size: 12px;
  padding: 5px 10px 5px 10px;
  margin: 0;
  color: white;
}

.bannerButtonDisabled:hover {
  border: 2px solid black;
  background-position: bottom left;
  color: black;
}

.bannerButtonDisabledBig {
  border: 2px solid rgb(141, 140, 140);
  background: linear-gradient(to top, white 50%, grey 50%);
  background-size: 100% 200%;
  background-position: top left;
  transition: all 0.3s ease-out;
  font-family: inherit;
  font-weight: bolder;
  text-transform: uppercase;
  font-size: 12px;
  padding: 10px 15px 10px 20px;
  margin: 30px auto 0;
  color: white;
}

.bannerButtonDisabledBig:hover {
  border: 2px solid black;
  background-position: bottom left;
  color: black;
}
