.chart{
    
    
}

.chart canvas {
    width: 100% !important;
    height:500px !important; 
    border: 1px solid rgb(196, 194, 194);
    margin-bottom: 10px;
    /* font-size: 12px !important; */
}