.container {
  width: 83%;
  height:100%;
  background: black;
  color: white;
  position: absolute;
  top: 0;
  display: flex;
  align-items: center;
  align-content: center;
  flex-direction: column;
}

.code,
.text,
.button {
  display: flex;
  justify-content: center;
  padding: 25px;
}

.code {
  font-size: 70px;
}

.logo {
  display: flex;
  justify-content: center;
}
.logo img {
  width: 200px;
  max-width: 300px;
}
