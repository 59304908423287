.header {
  background: black;
  color: white;
  width: 100%;
  border-radius: 5px;
  padding: 10px;
}

.header svg {
  vertical-align: middle;
  padding: 5px;
}

#divFinalCommandeAffiché,
#divFinalDCICP,
#divFinalDCPIL {
  display: block;
}

#divFinalCommandeAffichéNone,
#divFinalDCICPNone,
#divFinalDCPILNone {
  display: none;
}

#divFinalCommandeAffiché a,
#divFinalDCICP a,
#divFinalDCPIL a {
  text-decoration: none;
}

.copyBtn {
  display: flex;
  justify-content: center;
  align-items: center;
}

.tooltipBtn {
  cursor: pointer;
  padding: 5px;
  width: 24px;
  height: 24px;
  z-index: 6;
}

.tooltipBtn:hover svg {
  color: #1a8f9b;
}

.tooltipBtn .tooltiptext {
  visibility: hidden;
  /* visibility: visible; */
  width: 220px;
  max-width: 250px;
  background-color: rgb(252, 242, 227);
  color: rgb(58, 58, 58);
  text-align: center;
  font-size: small;
  font-weight: lighter;

  border-radius: 6px;
  padding: 10px 0;

  /* Position the tooltip */
  position: relative;
  left: -230px;
  bottom: 30px;
}

.tooltipBtn:hover .tooltiptext {
  visibility: visible;
}

.boxHisto {
  padding: 6px 10px 0px 10px !important;
}

.boxHisto h2 {
  margin-bottom: 10px;
  margin-top: 20px;
  text-align: center;
  width: 100%;
}

/* Vient de storedCArd */

.title {
  margin-bottom: 10px;
  margin-top: 3rem;
  text-align: center;
  width: 100%;
}

.gridContainer {
  height: 80vh;
  width: 100%;
  background: white;
  font-family: inherit;
}

.gridContainer .tableHeader {
  font-weight: bold;
  color: black;
}

.gridContainer .invoiceNumber {
  font-weight: bold;
}

.gridContainer .SFNumber {
  padding-left: 20px;
}

.MuiDataGrid-root .MuiDataGrid-columnHeaderTitle {
  font-weight: bold;
}

.delivered,
.notDelivered,
.unknown {
  display: flex;
  justify-content: center;
  align-items: center;
}

.delivered svg {
  color: #1ba9aa;
}

.noResult {
  width: 100%;
  text-align: center;
}

.see {
  color: black;
  cursor: pointer;
}

.buttonDiv {
  display: flex;
  justify-content: space-evenly;
}

.delete {
  color: red;
  cursor: pointer;
}

.noResult svg {
  vertical-align: bottom;
}

.notDelivered svg {
  color: orange;
}

.unknown svg {
  color: lightgrey;
}

.dialogTitle {
  font-weight: bold !important;
  display: inline-block;
  margin: auto;
}

.legend {
  margin-top: 25px;
  font-weight: 400;
  font-style: italic;
  color: rgb(58, 58, 58);
  font-size: 0.9em;
}

.tabDocument {
  min-width: 700px;
  min-height: 300px;
}

.tabDocHeader {
  min-height: 30px;
  color: red;
}

.successImport {
  color: green;
  font-weight: bold !important;
}

.errorImport {
  color: red;
  font-weight: bold !important;
}

.hand {
  cursor: pointer;
}

.hand:hover {
  color: #1a8f9b;
}

.containerGrid {
  height: 25vh;
  width: 100%;
  margin-bottom: 30px;
}

.viewContainer {
  height: 25vh;
  width: 98%;
  margin: 0 auto;
  background-color: white;
}

.grey {
  background-color: #f9f9f9;
  height: 42vh;
  width: 85vh;
}

.refresh {
  display: flex;
  padding: 20px;
  justify-content: flex-end;
  align-items: center;
}

.refreshItem {
  display: flex;
  padding: 5px;
  align-items: center;
  border: 1px solid rgb(204, 204, 204);
  border-radius: 5px;
}

.refreshItem:hover {
  cursor: pointer;
  background-color: #f9f9f9;
}

.textRefresh {
  margin-left: 8px;
}
